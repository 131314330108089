import { graphql, PageProps } from 'gatsby';
import * as React from 'react';
import Layout from '../components/layout';
import Posts from '../components/posts';

const IndexPage: React.FC<PageProps<GatsbyTypes.BlogIndexQuery>> = ({ data }) => {
  const posts = data.allMdx.nodes;
  return (
    <Layout pageTitle="Top" description="jiko21's techblog" url="">
      {posts.map((node) => (
        <Posts key={node.id} node={node} />
      ))}
    </Layout>
  );
};

export const query = graphql`
  fragment BlogDataInfo on Mdx {
    id
    slug
    frontmatter {
      title
      date(formatString: "YYYY/MM/DD")
      tags
    }
  }

  query BlogIndex {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        ...BlogDataInfo
      }
    }
  }
`;

export default IndexPage;
